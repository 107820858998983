import {axiosInstance} from '../../services/interceptor';
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

// fetch products
export const fetchProducts = () => {
  return dispatch => {
    axiosInstance.get('/productos')
        .then(response => {
            dispatch(fetchProductsSuccess(response.data));
        })
        .catch(error => {
          // Maneja cualquier error de la solicitud aquí si es necesario
          console.error('Error fetching banner:', error);
        });
  };
  
};
