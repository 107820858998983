import {axiosInstance} from '../../services/interceptor';
export const FETCH_BANNER_SUCCESS = "FETCH_BANNER_SUCCESS";

export const fetchDataSuccess = (data) => ({
    type: FETCH_BANNER_SUCCESS,
    payload: data,
  });


export const setBanner = () => {
    return dispatch => {
      // Realiza la solicitud GET utilizando Axios
      axiosInstance.get('/slider')
        .then(response => {
            dispatch(fetchDataSuccess(response.data));
        })
        .catch(error => {
          // Maneja cualquier error de la solicitud aquí si es necesario
          console.error('Error fetching banner:', error);
        });
    };
  };